export class InjectionServiceTypeModel {
    constructor({
        id,
        description,
    }){
        this.id = id;
        this.description = description;
    }

    static fromJson(json){
        return new InjectionServiceTypeModel({
            id: json.id,
            description: json.nome,
        });
    }
}