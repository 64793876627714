export class PermissionModel {
  constructor({ sector, group, funcionalities, responsibleSector, funcionalitiesId }) {
    this.sector = sector;
    this.group = group;
    this.funcionalities = funcionalities;
    this.funcionalitiesId = funcionalitiesId;
    this.responsibleSector = responsibleSector;
  }

  static fromJson(json) {
    return new PermissionModel({
      sector: json.setor,
      group: json.grupo,
      funcionalities: json.funcionalidade,
      responsibleSector: json.setor_responsavel,
      funcionalitiesId: json.funcionalidade_id
    });
  }
}
