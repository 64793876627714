export class EmployeeModel {
    constructor({
        id,
        fullName,
    }){
        this.id = id;
        this.fullName = fullName;
    }

    static fromJson(json){
        return new EmployeeModel({
            id: json.colaborador_id ?? json.id,
            fullName: json.nomeCompleto,
        });
    }

    minimalToRemote(){
        return {
            colaborador_id: this.id
        }
    }
}