<template>
    <section>
        <div class="titulo">
            <div class="margem container">
                <h2>Indicadores da Injeção</h2>
            </div>
        </div>

        <div class="margem container">
            <div class="margem bloco container linha">
                <div>
                    <h3>Tempo de ciclo médio por peça por produção (em segundos)</h3>
                    <div id="line-chart-wrapper">
                        <LineChart :chart-data-prop="lineChartData" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import LineChart from "@/components/LineChart.vue";

export default {
    components: {
        LineChart,
    },
    data() {
        return {
            lineChartData: {
                labels: ["OP 2024/02354", "OP 2024/02359", "OP2024/02372", "OP 2024/02359", "OP 2024/02372", "OP 2024/02359", "OP 2024/02372", "OP 2024/02359", "OP 2024/02372"],
                datasets: [
                    {
                        label: "Tempo de ciclo (segundos)",
                        data: [40, 120, 24, 24, 87, 65, 45, 30, 38],
                    },
                ],
            },
        };
    },
};
</script>

<style scoped>
h3 {
    text-align: center;
}
</style>
