<template>
    <section
        v-for="ele of opData"
        :key="ele"
        class="dashboard-wrapper"
        :style="{
            minWidth: 50 / opData.length + '%',
        }"
    >
        <div class="titulo margem">
            <h2 style="padding: 0px; margin: 0px" class="alinha-centro">
                {{ ele.machineName }}
            </h2>
            <h1 style="padding: 0px; margin: 0px" class="alinha-centro">
                {{ ele.productName }}
            </h1>
        </div>
        <div class="grid-3">
            <div class="bloco margem">
                <div class="fonte-fraca">OP:</div>
                <div class="lead">{{ ele.opNum.split("/0")[1] }}</div>
            </div>
            <div class="bloco margem">
                <div class="fonte-fraca">Quantidade:</div>
                <div class="lead">{{ ele.qtExpected }}</div>
            </div>
            <div class="bloco margem" :class="isDeliveryLate(ele.deliveryDate)">
                <div class="fonte-fraca" :class="isDeliveryLate(ele.deliveryDate)">Entrega:</div>
                <div class="lead">
                    {{ new Date(ele.deliveryDate).toLocaleDateString().split("/202")[0] }}
                </div>
            </div>
        </div>
        <div class="grid-2 margem">
            <div class="bloco margem">
                <div class="fonte-fraca">Status:</div>
                <!-- <div class="lead">
          {{ ele.serviceType }}
        </div> -->
                <div class="lead">{{ ele.isPaused ? "Parada" : "Operando" }}</div>
            </div>
            <!-- <div class="bloco margem">
        <div class="fonte-fraca">Status:</div>
        <div class="lead">{{ ele.isPaused ? "Parada" : "Em operação" }}</div>
      </div> -->
            <div class="bloco margem" :class="ele.isPaused ? 'bg-atrasado' : 'bg-branco'">
                <div class="fonte-fraca">Tempo parado</div>
                <div class="lead">{{ calculatePercentage(ele.downTimeSeconds, ele.totalTimeMinutes) }}%</div>
            </div>
        </div>

        <div class="margem bloco container">
            <div class="fonte-fraca">Tempo médio de produção de peça:</div>
            <div class="lead">{{ calculateAverageTime(ele) }}s</div>
        </div>

        <div class="bloco margem bg-sucesso" style="color: black">
            <div class="grid-2" style="padding-bottom: 15px">
                <div class="coluna bloco margem">
                    <div class="fonte-fraca">Peças produzidas</div>
                    <div class="lead">{{ ele.qtyDone }}</div>
                </div>
                <div class="coluna bloco margem">
                    <div class="fonte-fraca">Última atualização</div>
                    <!-- <div class="fonte-fraca">atualização</div> -->
                    <div class="lead">
                        {{ new Date(ele.lastUpdate).toLocaleString().split("/2024,")[0] }} -

                        <!-- {{ new Date(ele.lastUpdate).toLocaleString().split(",")[1] }} -->
                        {{ new Date(ele.lastUpdate).toLocaleString().split(":")[0].substring(11) }}:{{
                            new Date(ele.lastUpdate).toLocaleString().split(":")[1]
                        }}
                    </div>
                </div>
            </div>
            <progress :value="(ele.qtyDone / ele.qtExpected) * 100" max="100"></progress>
        </div>


        <!-- <div class="margem bloco container" style="margin-top: 12px">
      <div class="linha space-around">
        <div>
          <div class="fonte-fraca">Borra:</div>
          <div class="lead">{{ ele.scrap.toFixed(2) }}kg</div>
        </div>
        <div>
          <div class="fonte-fraca">Refugo:</div>
          <div class="lead">{{ ele.residue.toFixed(2) }}kg</div>
        </div>
      </div>
      <div class="linha space-around">
        <div>
          <div class="fonte-fraca">Horas máquina:</div>
          <div class="lead">{{ ele.machineHour }}h {{ ele.machineMin }}m</div>
        </div>

        <div>
          <div class="fonte-fraca">Horas paradas:</div>
          <div class="lead">{{ ele.downTimeHour }}h {{ ele.downTimeMin }}m</div>
        </div>
      </div>
    </div> -->

        <div class="bloco linha alinha-centro space-around" style="margin-top: 6px">
            <h1 class="lead">2°</h1>

            <div>
                <div class="fonte-fraca">OP</div>
                <h3></h3>
            </div>
            <div>
                <div class="fonte-fraca">Produto</div>
                <h3></h3>
            </div>
            <div>
                <div class="fonte-fraca">Qtd</div>
                <h3></h3>
            </div>
        </div>
        <!-- <div class="bloco linha alinha-centro space-around bg-grey" style="margin-top: 6px;">
        <div>
          <div class="fonte-fraca">Ordem:</div>
          <h3>2°</h3>
        </div>
      <div>
        <div class="fonte-fraca">OP</div>
        <h3>2798</h3>
      </div>
      <div>
        <div class="fonte-fraca">Produto</div>
        <h3>TAMPA DE ACABAMENTO INTERNA X115</h3>
      </div>
      <div>
        <div class="fonte-fraca">Qtd</div>
        <h3>1050</h3>
      </div>
    </div> -->
    </section>
</template>

<script>
// import PieChart from "@/components/PieChart.vue";
import { InjectionPanelDatasource } from "@/data/datasource/InjectionPanelDatasouce";

const dataSource = new InjectionPanelDatasource();

export default {
    components: {
        // PieChart,
        // LineChart,
    },
    data() {
        return {
            opData: [],
            interval: null,
            lineChartData: {
                labels: ["t1", "t2", "t3"],
                datasets: [
                    {
                        label: "Tempo médio de produção (segundos)",
                        data: [40, 120, 24, 24, 87, 65, 45, 30, 38],
                    },
                ],
            },
        };
    },
    mounted() {
        this.interval = setInterval(async () => {
            this.opData = await this.getData();
        }, 15000);
    },
    async created() {
        this.opData = await this.getData();
    },
    methods: {
        async getData() {
            let data = await dataSource.getData();
            return data;
        },

        calculatePercentage(partial, total) {
            return ((partial / total) * 100).toFixed(2);
        },

        calculateAverageTime(op) {
            var time = Math.floor(op.machineSeconds / op.qtyDone);
            return time;
        },

        isDeliveryLate(deliveryDate) {
            let delivery = new Date(deliveryDate).getTime();
            let today = new Date().getTime();

            if (today > delivery) {
                return "bg-blink";
            } else {
                return "bg-branco";
            }
        },

        isMachinePaused(value) {
            if (value) {
                return true;
            } else {
                return false;
            }
        },
    },
    beforeUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    },
};
</script>

<style scoped>
.lead {
    white-space: nowrap;
    overflow: hidden;
}

div {
    /* white-space: nowrap; */
    overflow: hidden;
    /* text-overflow: ellipsis; */
    /* text-wrap: nowrap; */
}

h1 {
    white-space: nowrap;
}

.wrap-text > div > .lead {
    white-space: normal;

    overflow: visible;
    /* text-overflow: clip; */
    /* text-wrap: normal; */
}

.dashboard-wrapper {
    border-right: 2px solid grey;
    padding: 0px 20px;
    /* max-width: 30%; */
    width: 500px;
}

.space-around {
    justify-content: space-around;
}

.bg-branco {
    color: black;
}

.bg-atrasado {
    background-color: yellow;
}

.bg-grey {
    color: rgba(0, 0, 0, 0.7);
    background-color: rgba(108, 99, 71, 0.1);
}

.bg-blink {
    animation: blink 1s infinite alternate;
}

@keyframes blink {
    from {
        color: black;
        background-color: white;
    }
    to {
        color: white;
        background-color: var(--cor-erro);
    }
}

.fonte-fraca {
    font-size: x-large;
}

.lead {
    font-size: 38px;
}

.margem {
    margin: 0px;
    padding: 14px;
}

.margem-menor {
    margin-top: 12px;
}

@media screen and (min-width: 2600px) {
    .fonte-fraca {
        font-size: 64px;
    }

    .lead {
        font-size: 94px;
        font-weight: bold;
    }
}
</style>
