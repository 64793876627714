export class InjectionMachineModel {
    constructor({
        id,
        name,
        orderNum,
    }){
        this.id = id;
        this.name = name;
        this.orderNum = orderNum;
    }

    static fromJson(json){
        return new InjectionMachineModel({
            id: json.id,
            name: json.nome,
            orderNum: json.ordem,
        });
    }
}