export class InjectionOrderModel {
  constructor({
    id,
    opNum,
    productCode,
    productName,
    quantityExpected,
    deliveryDate,
    clientName,
    quantityProduced,
    quantityRefugo,
    quantityBorra,

    plannedForMachine,
    isFinished,
  }) {
    this.id = id;
    this.opNum = opNum;
    this.productCode = productCode;
    this.productName = productName;
    this.quantityExpected = quantityExpected;
    this.deliveryDate = deliveryDate;
    this.clientName = clientName;
    this.quantityProduced = quantityProduced;
    this.quantityRefugo = quantityRefugo;
    this.quantityBorra = quantityBorra;
    this.isFinished = isFinished;

    this.plannedForMachine = plannedForMachine ?? 0;
  }

  static fromJson(json) {
    return new InjectionOrderModel({
      id: json.op_cod,
      opNum: json.opNum,
      productCode: json.produto_cod,
      productName: json.produto_desc,
      quantityExpected: json.qt,
      deliveryDate: json.dtPrevista,
      clientName: json.cliente_nome,
      quantityProduced: json.qtProduzida ?? 0,
      quantityRefugo: json.qtRefugo ?? 0.0,
      quantityBorra: json.qtBorra ?? 0.0,
      isFinished: json.encerrado ?? false,
    });
  }
}
