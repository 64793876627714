<template>
  <HeaderComponent :moduleName="$moduleName" :buildMode="envMode"/>

  <main id="main">
    <MenuLateral />
    <RouterView />
  </main>
</template>

<script>
import MenuLateral from "@/components/MenuLateral.vue";
import { HeaderComponent } from "roboflex-thalamus-componentes-vue3-lib";
import { RouterView } from "vue-router";

export default {
  name: "App",
  components: {
    MenuLateral,
    HeaderComponent,
    RouterView,
  },
  data() {
    return {
      envMode: process.env.NODE_ENV // Acessa modo de build
    };
  }
};
</script>

<style>
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--cor-separador);
  border-radius: 20px;
}
</style>
