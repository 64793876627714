<template>
  <h3>{{ title }}</h3>
  <Line :data="chartDataProp" :options="chartConfig" />
</template>

<script>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "vue-chartjs";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  ChartDataLabels
);

export default {
  components: {
    Line,
  },
  data() {
    return {
      chartConfig: {
        maintainAspectRatio: false,
        // maxHeight: 20,
        plugins: {
          datalabels: {
            anchor: "center",
            clamp: true,
            align: "10",
            // font: {
            //   backgroundColor: "#FFFFFF",
            //   weight: "bold",
            //   size: 48,
            // },
            formatter: function (value) {
              return value + "s";
            },
          },
          legend: {
            display: false,
          },
        },
        layout: {
          padding: {
            right: 40,
          },
        },
        elements: {
          point: {
            // radius: 10,
            backgroundColor: "#5682e3",
          },
          line: {
            // borderWidth: 8,
            borderColor: "#9da9c4",
          },
        },
      },
    };
  },
  props: {
    chartDataProp: Object,
    title: String,
  },
};
</script>
