<template :class="menuAtivo == 1 ? 'esconde' : ''">
  <aside>
    <div class="titulo margem efeito">
      <div class="m-icone">
        <a href="#" class="icone-menu" title="Menu" onclick="document.getElementById('main').classList.toggle('esconde');return false"></a>
      </div>
      <nav class="jm jm-inc none" id="menuIncluir">
        <div class="separador">
          <a href="#">Incluir projeto</a>
          <a href="monitoramento.html">Incluir projeto</a>
        </div>
      </nav>
    </div>
    <div class="separador margem">
      <div class="m-icone">
        <a class="icone-input ativo" title="Injeção" id="Injeção"></a>
      </div>
    </div>
    <nav class="nav-maior separador none">
      <a href="#" class="icone-input ativo">Injeção</a>
    </nav>

    <nav class="nav-maior separador" v-if="permissionLoaded">
      <router-link to="/" active-class="ativo">Painel Injetora</router-link>


      <router-link v-if="checkIfHasPermission(61)" to="/painel-edicao" active-class="ativo">Editar apontamento</router-link>

      <router-link to="/planejamento-injecao" active-class="ativo">Planejamento de injeção</router-link>
      <router-link v-if="false" to="/indicadores-injecao" active-class="ativo">Indicadores</router-link>
    </nav>
  </aside>
</template>

<script>
import { PermissionDatasource } from "@/data/datasource/PermissionDatasource.js";
const permissionDatasource = new PermissionDatasource();

export default {
  components: {},
  data() {
    return {
      permission: null,
      permissionLoaded: false,
    };
  },
  methods: {
    checkIfHasPermission(funcionalidadeId) {

      const hasPermission = this.permission.funcionalitiesId.some((ele) => ele == funcionalidadeId);


      return hasPermission;
    },
  },

  async created() {
    this.permission = await permissionDatasource.getUserPermission();
    this.permissionLoaded = true;
  },
};
</script>
