// import { EmployeeModel } from "./EmployeeModel";

import { EmployeeModel } from "./EmployeeModel";

export class InjectionActivityModel {
  constructor({
    id,
    opId,
    machineId,
    quantityProduced,
    quantityRefugo,
    quantityBorra,
    isPaused,
    startDateTime,
    endDateTime,
    serviceTypeId,
    team,

    isDropdownVisible,
    // isEditingMachineId,
    // isEditingQuantityProduced,
    // isEditingQuantityRefugo,
    // isEditingQuantityBorra,
    // isEditingStartDate,
    // isEditingEndDate,
    // isEditingServiceType,
    // isEditingTeam,
  }) {
    this.id = id;
    this.opId = opId;
    this.machineId = machineId;
    this.quantityProduced = quantityProduced;
    this.quantityRefugo = quantityRefugo;
    this.quantityBorra = quantityBorra;
    this.isPaused = isPaused;
    this.startDateTime = startDateTime;
    this.endDateTime = endDateTime;
    this.serviceTypeId = serviceTypeId;
    this.team = team;

    this.isDropdownVisible = isDropdownVisible ?? false;
  }

  static fromJson(json) {
    return new InjectionActivityModel({
      id: json.id,
      opId: json.op_id,
      machineId: json.maquina_id,
      quantityProduced: json.qtProduzida ?? 0,
      quantityRefugo: json.qtRefugo ?? 0,
      quantityBorra: json.qtBorra ?? 0,
      isPaused: json.pausado,
      startDateTime: new Date(json.dtInicio),
      endDateTime: json.dtFim != null ? new Date(json.dtFim) : null,
      serviceTypeId: json.servico_id,
      team: (json.colaboradores ?? []).map((ele) =>
        EmployeeModel.fromJson(ele)
      ),
    });
  }

  toRemote() {
    let formatedTeam = []
    for(let ele of this.team){
      formatedTeam.push(ele.minimalToRemote())
    }

    return {
      dtInicio: new Date(this.startDateTime).toISOString().replace("T", " ").replace(".000Z",""),
      dtFim: this.endDateTime != null ? new Date(this.endDateTime).toISOString().replace("T", " ").replace(".000Z","") : null,
      servico_id: this.serviceTypeId,
      maquina_id: this.machineId,
      qtProduzida: this.quantityProduced,
      qtBorra: this.quantityBorra,
      qtRefugo: this.quantityRefugo,
      equipe: formatedTeam,
    };
  }
}
